import { useScorecardsValidation } from '@src/pages/Forms/ReviewCycle/ReviewCycle/hooks/useScorecardsValidation'
import React, { useEffect, useRef, useState } from 'react'
import {
  BottomSheet,
  Box,
  Button,
  Placeholder,
  Relative,
  Scrollbar,
  Widget,
} from '@revolut/ui-kit'
import { useStatusPopup } from './hooks/useStatusPopup'
import { InvalidState } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/ValidateScorecards/PopupBody'
import { ImageProps } from '@revolut/ui-kit'

enum PopupState {
  Default = 'Default',
  Valid = 'Valid',
  Invalid = 'Invalid',
}

interface Props {
  cycleId: number | string
  isOpen: boolean
  handleClose: VoidFunction
}

interface PopupElements extends Pick<ImageProps, 'image'> {
  title: string
  description?: string
  confirmLabel: string
  cancelLabel?: string
}

const popupStateToElementsMap: Record<PopupState, PopupElements> = {
  [PopupState.Default]: {
    title: 'Review eligible employees',
    description: 'Data for these employees will be validated',
    confirmLabel: 'Validate employee data',
    cancelLabel: 'Cancel',
    image: {
      default: `https://assets.revolut.com/assets/3d-images-v2/3D262.png`,
      '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D262@2x.png',
      '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D262@3x.png',
    },
  },
  [PopupState.Valid]: {
    title: 'Employee data is valid',
    confirmLabel: 'Finish',
    image: {
      default: `https://assets.revolut.com/assets/3d-images-v2/3D151A.png`,
      '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D151A@2x.png',
      '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D151A@3x.png',
    },
  },
  [PopupState.Invalid]: {
    title: 'We found some issues',
    confirmLabel: 'Validate again',
    cancelLabel: 'Cancel',
    image: {
      default: `https://assets.revolut.com/assets/3d-images-v2/3D354.png`,
      '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D354@2x.png',
      '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D354@3x.png',
    },
  },
}

export const ValidationEmployeePopup = ({ cycleId, isOpen, handleClose }: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const [popupState, setPopupState] = useState(PopupState.Default)
  const [scrollParent, setScrollParent] = useState<HTMLDivElement | undefined>(undefined)

  const { progress, isPending, isCompleted, isFailed, isValid, validate } =
    useScorecardsValidation(cycleId)
  const { showValidationOngoing, showValidationCompleted, showValidationFailed } =
    useStatusPopup()

  useEffect(() => {
    if (scrollRef?.current && !scrollParent) {
      setScrollParent(scrollRef.current)
    }
  }, [scrollRef?.current])

  useEffect(() => {
    if (isCompleted) {
      showValidationCompleted()
      setPopupState(isValid ? PopupState.Valid : PopupState.Invalid)
    } else if (isFailed) {
      showValidationFailed()
    } else if (isPending) {
      showValidationOngoing(progress)
    }
  }, [isValid, isPending, isCompleted, progress])

  const handleConfirm = () => {
    if (popupState === PopupState.Valid) {
      handleClose()
    } else {
      validate()
    }
  }

  const { title, description, confirmLabel, cancelLabel, image } =
    popupStateToElementsMap[popupState]

  return (
    <BottomSheet size="sm" open={isOpen} onClose={handleClose}>
      <Placeholder>
        <Placeholder.Image image={image} />
        <Placeholder.Title>{title}</Placeholder.Title>
        <Placeholder.Description>{description}</Placeholder.Description>
      </Placeholder>

      {isCompleted && (
        <Widget marginY="s-16">
          <Relative>
            <Box minHeight="s-8" maxHeight={300} borderRadius="r16" ref={scrollRef}>
              {popupState === PopupState.Invalid && (
                <InvalidState cycleId={cycleId} scrollParent={scrollParent} />
              )}
              <Scrollbar scrollRef={scrollRef} cornerSpace="s-8" offset="s-2" />
            </Box>
          </Relative>
        </Widget>
      )}
      <BottomSheet.Actions>
        <Button onClick={handleConfirm} pending={isPending}>
          {confirmLabel}
        </Button>
        {popupState !== PopupState.Valid && (
          <Button variant="secondary" onClick={handleClose}>
            {cancelLabel}
          </Button>
        )}
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
